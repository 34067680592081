import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import env from "@ludovicm67/react-dotenv";
const API_URL = process.env.REACT_APP_API_URL;
const Card = ({ item }) => {
  // Construct the full URL for the image
  const imageUrl = item.image ? `${API_URL}/${item.image.replace('\\', '/')}` : '';
  const { i18n } = useTranslation();
  const title = i18n.language === 'ar' ? item.title.ar : item.title.en;

  return (
    <div className='box'>
  <Link to={`/SinglePage/${item._id}`}>
    <div className='img'>
      {/* Check if imageUrl exists and is valid */}
      {imageUrl ? (
        <img src={imageUrl} alt='Article' />
      ) : (
        <p>Image not available</p> // Placeholder text if image URL is invalid
      )}
    </div>
  </Link>
  <div className='text'>
    <span className='category p-1'>{i18n.t(`${item.category}`)}</span>
    <div className="relative mt-4">
      <Link to={`/SinglePage/${item._id}`}>
        <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>
        <h1 className="relative text-white">{title}</h1>
      </Link>
    </div>
    <div className='author flex'>
      <Link to={`/SinglePage/${item._id}`}>
        <span>{new Date(item.createdAt).toLocaleDateString()}</span>
      </Link>
    </div>
  </div>
</div>

  
  );
};

export default Card;
